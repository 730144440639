import { AccountDetailInfo } from "@sellernote/_shared/src/types/forwarding/adminAccount";

import { AccountCategoryState } from "pages/account-management/Content/types";

const getCategoryState = (
  detailData: AccountDetailInfo | undefined
): AccountCategoryState => ({
  sales:
    detailData?.category === "all" || detailData?.category === "sales" || false,
  payable:
    detailData?.category === "all" ||
    detailData?.category === "payable" ||
    false,
});

export default getCategoryState;
