import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { getQuotationComment } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import QuotationEtcFormList from "@sellernote/_shared-for-forwarding-admin/src/components/QuotationEtcFormList";
import QuotationFeeForm from "@sellernote/_shared-for-forwarding-admin/src/components/QuotationFeeForm";
import ItemInfoForRead from "@sellernote/_shared-for-forwarding-admin/src/containers/itemInfo/ItemInfoForRead";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import TemplateSearch from "@sellernote/_shared-for-forwarding-admin/src/containers/TemplateSearch";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import QuotationUserMailModal from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/components/QuotationUserMailModal";

import useQuotationData from "../_hooks/useQuotationData";
import useDefaultDataForApplyBidForm from "./useDefaultDataForApplyBidForm";
import useUpdateRequest from "./useUpdateRequest";

export type BidApplyParams = {
  id: string;
  freightType: FreightType;
};

const BidApply = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const { id, freightType } = useParams<BidApplyParams>();

  const {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  } = useQuotationData({
    shipmentId: id,
  });

  const { data: applyFares } = ADMIN_BID_QUERY.useGetAdminBidApplyFares({
    bidId: Number(id),
  });

  // 해당 운임 불러오기 여부
  const [isLoadedApplyFare, setIsLoadedApplyFare] = useState<boolean>(false);

  const { getDefaultFeeData } = useDefaultDataForApplyBidForm({
    bidDetail,
    applyItems,
    freightType,
    exchangeRateList,
  });

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyBidFormData>({
    defaultValues: {
      freightPaymentType: bidDetail?.isImport ? "cc" : "pp",
      localFeeData: [],
      freightFeeData: [],
      domesticFeeData: [],
      inlandFeeData: [],
      otherFeeData: [],
      taxFeeData: [],
      expiredAt: new Date(),
      isTransit: false,
    },
  });

  const defaultFeeDataUpdate = useCallback(() => {
    setValue("localFeeData", getDefaultFeeData("localCost"));
    setValue("freightFeeData", getDefaultFeeData("freightCost"));
    setValue("domesticFeeData", getDefaultFeeData("domesticCost"));
    setValue("inlandFeeData", getDefaultFeeData("inlandCost"));
    setValue("otherFeeData", getDefaultFeeData("otherCost"));
    setValue("taxFeeData", getDefaultFeeData("tax"));
    setValue("expiredAt", new Date());
    setValue("isTransit", false);
    setValue("comment", getQuotationComment(bidDetail));

    setIsLoadedApplyFare(true);
  }, [bidDetail, getDefaultFeeData, setValue]);

  const {
    isLoading,
    showsQuotationUserMailModal,
    setShowsQuotationUserMailModal,
    handleQuotationUserUpdate,
    handleQuotationUpdate,
  } = useUpdateRequest({
    shipmentDetail: bidDetail,
    linerList,
    exchangeRateList,
    formData: watch(),
    onSnackbarOpen: handleSnackbarOpen,
  });

  const forwarderFareUpdate = () => {
    if (!applyFares) {
      handleSnackbarOpen("실시간 견적이 없습니다.", "warning");
      return;
    }
    setValue("localFeeData", applyFares.localFee);
    setValue("freightFeeData", applyFares.freightFee);
    setValue("domesticFeeData", applyFares.domesticFee);
    setValue("inlandFeeData", applyFares.inlandFee);
    setValue("otherFeeData", applyFares.otherFee);
    setValue("taxFeeData", applyFares.taxFee);
    setValue("liner", applyFares?.liner || "");
    setValue("leadtime", applyFares?.leadtime || 0);
    setValue("freightPaymentType", applyFares?.freightPaymentType || "cc");
    setValue("isTransit", applyFares?.isTransit ?? false);
    setValue("expiredAt", applyFares?.expiredAt || new Date());

    setIsLoadedApplyFare(true);
  };

  const noApplyFareMessage = bidDetail?.isImport
    ? "해당 구간에 등록된 임시 견적이 없습니다. 쉽다 기본항목을 불러오시겠습니까?"
    : "해당 구간에 등록한 임시 견적이 없습니다.";

  if (isShipmentDetailFetching) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid
        container
        gap={1}
        component="form"
        onSubmit={handleSubmit(handleQuotationUpdate)}
      >
        {bidDetail && exchangeRateList && (
          <ItemInfoForRead
            bidDetail={bidDetail}
            exchangeRateList={exchangeRateList}
          />
        )}

        {!isLoadedApplyFare && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <ErrorOutlineIcon color="error" />
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" color="error">
                {applyFares && isNotEmptyObjectOrArray(applyFares)
                  ? "해당 구간에 등록한 임시 견적이 있습니다. 해당 견적을 불러오시겠습니까?"
                  : noApplyFareMessage}
              </Typography>
            </Grid>

            {bidDetail?.isImport && (
              <Grid item>
                <Typography variant="subtitle2">
                  {applyFares
                    ? "아니오를 누르시면 쉽다 기본항목을 제공합니다."
                    : "아니오를 누르시면 처음부터 작성 가능합니다."}
                </Typography>
              </Grid>
            )}

            <Grid item container justifyContent="center" spacing={2}>
              {bidDetail?.isImport && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      if (applyFares && isNotEmptyObjectOrArray(applyFares)) {
                        return defaultFeeDataUpdate();
                      }
                      setIsLoadedApplyFare(true);
                    }}
                  >
                    아니오
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    // 임시견적 항목이 있는 경우 임시견적 항목을 채워준다.
                    if (applyFares && isNotEmptyObjectOrArray(applyFares)) {
                      forwarderFareUpdate();
                      return;
                    }
                    // 수출일때는 하드코딩된 기본 견적 항목을 제공하지 않음
                    if (!bidDetail?.isImport) {
                      setIsLoadedApplyFare(true);
                      return;
                    }
                    defaultFeeDataUpdate();
                    return;
                  }}
                >
                  {bidDetail?.isImport ? "예" : "확인"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {isLoadedApplyFare && exchangeRateList && (
          <TemplateSearch
            bidId={Number(id)}
            templateCategory={"all"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"all"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"localCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"localFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"localFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"freightCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"freightFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"freightFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"domesticCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"domesticFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"domesticFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"inlandCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"inlandFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"inlandFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"otherCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"otherFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"otherFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && exchangeRateList && applyItems && (
          <QuotationFeeForm
            bidId={Number(id)}
            quotationFeeFormCategory={"tax"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"taxFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"taxFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetail}
          />
        )}

        {isLoadedApplyFare && (
          <QuotationEtcFormList
            freightType={freightType}
            control={control}
            errors={errors}
            linerList={linerList}
          />
        )}

        {isLoadedApplyFare && (
          <Grid item>
            <Button variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : /** 페이지 진입 시 확정견적이 이미 있다면 확정 견적 재수정 */
              bidDetail?.quotationsUser.length === 0 ? (
                "견적 초안 생성"
              ) : (
                "확정 견적 재수정"
              )}
            </Button>
          </Grid>
        )}
      </Grid>

      {showsQuotationUserMailModal && (
        <QuotationUserMailModal
          showsQuotationUserMailModal={showsQuotationUserMailModal}
          onQuotationUserMailModalClose={() =>
            setShowsQuotationUserMailModal(false)
          }
          onQuotationUserUpdate={handleQuotationUserUpdate}
        />
      )}
    </Layout>
  );
};

export default withRequireAuth(BidApply);
