import { Box } from "@mui/material";

import ButtonGroup from "./ButtonGroup";
import Filter from "./Filter";

export default function FilterAndButton({
  onCreateModalOpen,
}: {
  onCreateModalOpen: () => void;
}) {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Filter />

      <ButtonGroup onCreateModalOpen={onCreateModalOpen} />
    </Box>
  );
}
