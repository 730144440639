import { Box } from "@mui/material";
import { useSetAtom } from "jotai";

import { AccountListInfo } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import Table from "@sellernote/_shared-for-forwarding-admin/src/components/Table";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

import useHandleAccountTablePagination from "./hooks";
import { getRowContent } from "./utils";

import { HEAD_CELL_LIST } from "./constants";

export default function AccountManagementTable({
  total,
  accountList,
}: {
  total: number;
  accountList: AccountListInfo[];
}) {
  const setAccountDetailId = useSetAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_DETAIL_ID
  );

  const rowList = getRowContent({ accountList, setAccountDetailId });

  const { currentPage, perPage, changePage, changePerPage } =
    useHandleAccountTablePagination();

  return (
    <Box
      bgcolor="#ffffff"
      mt={2}
      border={1}
      borderColor={"#dddddd"}
      height="auto"
    >
      <Table
        isFixedHeader
        headCells={HEAD_CELL_LIST}
        showsTableVerticalLine
        rows={rowList}
        pagination={{
          totalCount: total,
          perPage,
          setPerPage: changePerPage,
          currentPage,
          setCurrentPage: changePage,
        }}
      />
    </Box>
  );
}
