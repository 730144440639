import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";

/**
 * 견적서 관련 페이지의 get 데이터 요청을 묶은 커스텀 훅
 *  - 견적 초안 제출 페이지(applyBid)
 *  - 견적 확정 페이지(confirm-quotation)
 *  - 견적 초안 수정 페이지(change-quotation)
 *  - 확정 견적 수정 페이지(change-user-quotation)
 */
function useQuotationData({ shipmentId }: { shipmentId: string }) {
  const { data: bidDetail, isFetching: isShipmentDetailFetching } =
    ADMIN_BID_QUERY.useGetAdminBidDetail({
      bidId: Number(shipmentId),
    });

  const { data: linerList } = ADMIN_COMMON_QUERY.useGetLinerList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList(
    {
      all: true,
      countryTarget: bidDetail?.locale,
    },
    // enabled 설정
    bidDetail?.locale !== undefined
  );

  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({
    isImport: bidDetail?.isImport,
    enabled: bidDetail?.isImport !== undefined,
  });

  return {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  };
}

export default useQuotationData;
