import { TextField } from "@mui/material";
import { useAtom } from "jotai";

import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

export default function AccountId() {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const handleChangeId = (id: number) => setFilter({ ...filter, page: 0, id });

  return (
    <TextField
      label="No."
      variant="outlined"
      size="small"
      sx={{ width: 150 }}
      onChange={({ target: { value } }) => handleChangeId(Number(value))}
    />
  );
}
