import { Dispatch, SetStateAction, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";

import {
  AdminBidDetail,
  BidCopyStatus,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";

import BidCopyConditions from "./BidCopyConditions";

const BidCopyModal = ({
  showsBidCopyModal,
  setShowsBidCopyModal,
  bidDetail,
}: {
  showsBidCopyModal: boolean;
  setShowsBidCopyModal: Dispatch<SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
}) => {
  const { status, projectStatus, isImport } = bidDetail;

  const importBidCopyModalStatus = useMemo(() => {
    if (status === "inProgress") {
      return projectStatus === "beforeContactPartner" ||
        projectStatus === "contactingPartner"
        ? projectStatus
        : "contactingPartner";
    }

    if (status === "finished") {
      return projectStatus === "canceled" || projectStatus === "failed"
        ? "committed"
        : "contactingPartner";
    }

    return status;
  }, [projectStatus, status]) as BidCopyStatus; // 의뢰 복사와 의뢰 상태가 달라 타입 단언 추가

  const exportBidCopyModalStatus = useMemo(() => {
    if (status === "inProgress") return "waitingFreight";

    if (status === "finished") {
      return projectStatus === "canceled" || projectStatus === "failed"
        ? "committed"
        : "waitingFreight";
    }

    return status;
  }, [projectStatus, status]) as BidCopyStatus; // 의뢰 복사와 의뢰 상태가 달라 타입 단언 추가

  return (
    <>
      <Modal
        modalBodySx={{ p: 2, overflow: "visible" }}
        isOpened={showsBidCopyModal}
        handleClose={() => setShowsBidCopyModal(false)}
        modalBody={
          <Box display="flex" flexDirection={"column"}>
            <Typography variant="h5">의뢰 복사</Typography>

            <Divider sx={{ mt: 1, mb: 2 }} />

            <BidCopyConditions
              bidDetail={bidDetail}
              bidCopyModalStatus={
                isImport ? importBidCopyModalStatus : exportBidCopyModalStatus
              }
              setShowsBidCopyModal={setShowsBidCopyModal}
            />
          </Box>
        }
      />
    </>
  );
};

export default BidCopyModal;
