import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@mui/material";

export default function useReturnToBidCrateButton() {
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.replace({
      pathname: `/bid/create`,
    });
  }, [history]);

  const ReturnToBidCreateButton = useMemo(() => {
    return (
      <Grid item>
        <Button onClick={handleButtonClick} variant="outlined">
          돌아 가기
        </Button>
      </Grid>
    );
  }, [handleButtonClick]);

  return { ReturnToBidCreateButton };
}
