import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtom } from "jotai";

import { AccountCategory } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

export default function TradeCategory() {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const handleTradeCategory = (category: AccountCategory | undefined) => {
    setFilter({ ...filter, page: 0, category: category });
  };

  return (
    <FormControl size="small">
      <InputLabel>거래형태</InputLabel>

      <Select
        value={filter.category}
        label="거래형태"
        onChange={(e) => {
          handleTradeCategory(e.target.value as AccountCategory | undefined);
        }}
        sx={{ width: 150 }}
      >
        <MenuItem value={undefined}>전체</MenuItem>
        <MenuItem value={"sales"}>매출</MenuItem>
        <MenuItem value={"payable"}>매입</MenuItem>
      </Select>
    </FormControl>
  );
}
