import React from "react";

import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import AdminUserList from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/admin-user";

const AdminUser = () => {
  return <AdminUserList />;
};

export default withRequireAuth(AdminUser);
