import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import { Incoterms } from "@sellernote/_shared/src/types/common/common";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";

function IncotermsForm({
  incotermsDefaultValueAndOptions,
}: {
  incotermsDefaultValueAndOptions: {
    defaultValue: Incoterms;
    options: Incoterms[];
  };
}) {
  const { control, setValue } = useFormContext<BidCreateFormData>();

  const [prevIncotermsOptions, setPrevIncotermsOptions] = useState(
    incotermsDefaultValueAndOptions.options
  );

  // To locate the bad setState()~ 에러를 없애기 위해서 callback-ref 사용
  const incotermsRef = useCallback(
    (node) => {
      if (node !== null) {
        if (incotermsDefaultValueAndOptions.options !== prevIncotermsOptions) {
          setPrevIncotermsOptions(incotermsDefaultValueAndOptions.options);
          setValue("incoterms", incotermsDefaultValueAndOptions.defaultValue);
        }
      }
    },
    [
      incotermsDefaultValueAndOptions.defaultValue,
      incotermsDefaultValueAndOptions.options,
      prevIncotermsOptions,
      setValue,
    ]
  );

  return (
    <Grid ref={incotermsRef} item container xs={12}>
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          인코텀즈
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteWithReactHookForm
          name={"incoterms"}
          control={control}
          options={incotermsDefaultValueAndOptions.options}
        />
      </Grid>
    </Grid>
  );
}

export default IncotermsForm;
