import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  Typography,
} from "@mui/material";

import { COPY_SHIPMENT_FOR_IMPORT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidCopyContactingPartnerForm,
  AdminBidDetail,
  AdminBidPartnerQuoteConsolidationItem,
  AdminBidPartnerQuoteContainerInfo,
  AdminBidPartnerQuoteProductInfo,
  BidCopyStatus,
  BidCopyTarget,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { checkIfBidHaveDomainPartner } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getConsolidationItemListForImport } from "@sellernote/_shared/src/utils/forwarding/bid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import CargoForConsol from "./CargoForConsol";
import CargoForGeneral from "./CargoForGeneral";

export default function ContactingPartnerModalForImport({
  showsContactingPartnerModal,
  setShowsContactingPartnerModal,
  bidDetail,
  copyStatusOfRadio,
  bidCopyTargetValue,
  setShowsBidCopyModal,
}: {
  showsContactingPartnerModal: boolean;
  setShowsContactingPartnerModal: Dispatch<SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
  copyStatusOfRadio: BidCopyStatus;
  bidCopyTargetValue: BidCopyTarget;
  setShowsBidCopyModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { mutate: copyShipmentForImport, isLoading: isLoadingForImport } =
    ADMIN_BID_QUERY.useCopyShipmentForImport();

  const { mutate: copyShipmentForExport, isLoading: isLoadingForExport } =
    ADMIN_BID_QUERY.useCopyShipmentForExport();

  const copyShipment = bidDetail.isImport
    ? copyShipmentForImport
    : copyShipmentForExport;

  const isLoading = bidDetail.isImport
    ? isLoadingForImport
    : isLoadingForExport;

  const handleAdminBidCopy = () => {
    copyShipment(
      {
        pathParams: {
          shipmentId: bidDetail.id,
        },
        targetStatus: copyStatusOfRadio,
        copyTarget: bidCopyTargetValue,
        ...watch(),
      },
      {
        onSuccess: (res) => {
          history.push(`/bid/detail/${res.data.id}`);
          setShowsBidCopyModal(false);
          setShowsContactingPartnerModal(false);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const sellerList =
    bidDetail.serviceType === "consolidation"
      ? getConsolidationItemListForImport(bidDetail.itemGroupList)
      : undefined;

  const getGeneralFrightInfo =
    (): COPY_SHIPMENT_FOR_IMPORT_REQ["generalFreightInfo"] => {
      if (bidDetail.serviceType === "consolidation") return;

      if (bidDetail.freightType === "FCL") {
        const result: AdminBidPartnerQuoteContainerInfo[] =
          bidDetail.itemGroupList.map((v) => {
            const subResult: AdminBidPartnerQuoteContainerInfo = {
              item: {
                itemName: v.item.name,
                itemNameEn: v.item.engName,
                isDangerous: v.item.isDangerous,
              },
              itemGroup: v.itemGroup,
              containerInfo: v.containerInfo,
            };

            return subResult;
          });

        return result;
      } else {
        const result: AdminBidPartnerQuoteProductInfo[] =
          bidDetail.itemGroupList.map((v) => {
            const subResult: AdminBidPartnerQuoteProductInfo = {
              item: {
                itemName: v.item.name,
                itemNameEn: v.item.engName,
                isDangerous: v.item.isDangerous,
                isDouble: v.item.canStack,
                unitSupply: v.item.unitSupply,
                volumeUnit: v.item.volumeUnit,
                weightUnit: v.item.weightUnit,
                packingType: v.item.packingType,
                vertical: v.item.vertical,
                horizontal: v.item.horizontal,
                height: v.item.height,
                cbm: v.item.cbm,
                weight: v.item.weight,
                quantity: v.item.quantity,
              },
            };

            return subResult;
          });

        return result;
      }
    };

  const getConsolidationFreightInfo =
    (): COPY_SHIPMENT_FOR_IMPORT_REQ["consolidationFreightInfo"] => {
      if (bidDetail.serviceType !== "consolidation") return;

      if (!sellerList) return;

      const result: AdminBidPartnerQuoteConsolidationItem[][] = sellerList.map(
        (seller) => {
          const result: AdminBidPartnerQuoteConsolidationItem[] =
            seller.itemList.map((v) => {
              const subResult: AdminBidPartnerQuoteConsolidationItem = {
                item: {
                  itemName: v.name,
                  itemNameEn: v.engName,
                  address: v.address || "",
                  volumeUnit: v.volumeUnit,
                  height: v.height,
                  vertical: v.vertical,
                  horizontal: v.horizontal,
                  quantity: v.quantity,
                  weight: v.weight,
                  weightUnit: v.weightUnit,
                  cbm: v.cbm,
                  packagingType: v.packingType,
                  exporter: v.exporter,
                },
              };

              return subResult;
            });

          return result;
        }
      );

      return result;
    };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminBidCopyContactingPartnerForm>({
    defaultValues: {
      commentf: "",
      commentd: "",
      generalFreightInfo: getGeneralFrightInfo(),
      consolidationFreightInfo: getConsolidationFreightInfo(),
    },
  });

  return (
    <Modal
      customModalBoxStyle={{
        maxWidth: "800px",
      }}
      isOpened={showsContactingPartnerModal}
      handleClose={() => setShowsContactingPartnerModal(false)}
      modalBody={
        <Grid
          container
          spacing={4}
          direction="row"
          component="form"
          onSubmit={handleSubmit(handleAdminBidCopy)}
        >
          <Grid item>
            <Typography variant="h5" component="div">
              파트너 컨택 요청
            </Typography>
          </Grid>

          <Grid item width={"100%"}>
            <Typography variant="h6" component="div">
              화물
            </Typography>

            {bidDetail.serviceType === "consolidation" ? (
              <CargoForConsol
                bidDetail={bidDetail}
                sellerList={sellerList}
                control={control}
              />
            ) : (
              <CargoForGeneral bidDetail={bidDetail} control={control} />
            )}
          </Grid>

          <Grid item width={"100%"}>
            <Typography variant="h6" component="div">
              코멘트
            </Typography>

            <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
              {checkIfBidHaveDomainPartner(bidDetail, "domestic") && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color={colors.blue[600]}
                  >
                    국내파트너 코멘트
                  </Typography>

                  <TextFieldWithReactHookForm
                    name={`commentd`}
                    control={control}
                    fullWidth
                  />
                </Box>
              )}

              {checkIfBidHaveDomainPartner(bidDetail, "foreign") && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color={colors.blue[600]}
                  >
                    해외파트너 코멘트
                  </Typography>

                  <TextFieldWithReactHookForm
                    name={`commentf`}
                    control={control}
                    fullWidth
                  />
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item container justifyContent={"end"} gap={2}>
            <Grid item>
              <Button disabled={isLoading} variant="contained" type="submit">
                {isLoading ? <CircularProgress size={25} /> : "컨택 요청"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setShowsContactingPartnerModal(false)}
              >
                돌아가기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
