import { MouseEvent, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions } from "antd";

import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { Country, Port } from "@sellernote/_shared/src/types/common/common";
import {
  AdminBidDetail,
  BidQuotation,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { QuotationsUser } from "@sellernote/_shared/src/types/forwarding/bid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import { changeQuotationsStatusToKr } from "@sellernote/_shared/src/utils/forwarding/bid";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import { numberFormat } from "services/util";

import UserCouponModal from "./UserCouponModal";

interface Props {
  isFixed: boolean;
  quotations: QuotationsUser;
  forwarderQuotations: BidQuotation[];
  containCustoms: boolean;
  exchangeRateList: ExchangeRate[];
  serviceType: string;
  bidData: AdminBidDetail;
  portData: Port[];
  countryList: Country[];
}

const MarginQuotationDesc = (props: Props) => {
  const { handleSnackbarOpen } = useSnackbar();

  const { quotations, bidData } = props;

  const history = useHistory();

  const queryClient = useQueryClient();

  const quotationsArray = [{ ...quotations }];

  const [showUserCouponModal, setShowUserCouponModal] = useState(false);

  const [_, setSessionInvoiceId] = useSessionStorage<string | null>(
    "invoiceId"
  );

  const handleMoveToTradingStatementPageClick = useCallback(
    (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      e.stopPropagation();

      const invoiceId = bidData?.bidInvoice
        ? bidData.bidInvoice.id.toString()
        : null;

      // 거래명세서에서 페이지에서 사용할 명세서 고유 아이디 저장
      setSessionInvoiceId(invoiceId);

      history.push(
        `/tradingStatement/invoice/${bidData.id}/${
          bidData.isImport ? "import" : "export"
        }`
      );
    },
    [
      bidData.bidInvoice,
      bidData.id,
      bidData.isImport,
      history,
      setSessionInvoiceId,
    ]
  );

  const downloadBid = async (v: any) => {
    try {
      // eslint-disable-next-line consistent-return
      await sendRequest({
        method: "get",
        apiType: "ShipdaAdminDefaultNew",
        path: `/bid/download/${v}`,
      }).then((response) => {
        if (response.status === 200) {
          return window.open(response.data.preSignedUrl.url);
        }
      });
    } catch (err) {
      handleSnackbarOpen("다운로드에 실패했습니다.", "error");
    }
  };

  const deleteCoupon = async () => {
    try {
      // eslint-disable-next-line consistent-return
      await sendRequest({
        method: "put",
        path: `/coupon/cancel/${bidData.id}`,
        apiType: "ShipdaAdminDefaultNew",
        data: {
          couponCode: bidData.coupon.code,
        },
      });
      handleSnackbarOpen("쿠폰을 취소했습니다.");
      queryClient.invalidateQueries(
        ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
          bidId: bidData.id,
        })
      );
    } catch (err) {
      handleSnackbarOpen("쿠폰취소에 실패했습니다.", "error");
    }
  };

  const checkIsCouponButton = (n: string) => {
    switch (n) {
      case "beforeContactPartner":
        return true;
      case "contactingPartner":
        return true;
      case "scheduling":
        return true;
      case "moving":
        return true;
      case "portEntryAndPrepareCustoms":
        return true;
      case "payment":
        return false;
      case "completeCustoms":
        return false;
      case "delivering":
        return false;
      case "finished":
        return false;
      case "canceled":
        return false;
      case "failed":
        return false;
      case "inSettlement":
        return false;
      case "settlementRejected":
        return false;
      case "settlementConfirmed":
        return false;
      case "settlementComplete":
        return false;
      default:
        return true;
    }
  };

  const getButtonDisabledValue = () => {
    if (
      bidData.status === "waiting" ||
      bidData.status === "waitingForExporterInfo"
    ) {
      return true;
    }
    if (bidData.projectStatus === "moving") {
      if (bidData.management.detailStatus === null) {
        return true;
      }
      return false;
    }

    if (
      bidData.projectStatus === "beforeContactPartner" ||
      bidData.projectStatus === "contactingPartner" ||
      bidData.projectStatus === "scheduling"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Card
        onClick={(e) => {
          history.push(`/bid/change-user-quotation/${bidData.id}`);
        }}
        hoverable={true}
        style={{ cursor: "pointer" }}
      >
        <Descriptions
          contentStyle={{ fontWeight: "bold" }}
          column={4}
          title={
            <div style={{ fontWeight: "bold", fontSize: 20 }}>확정견적</div>
          }
          extra={
            <div>
              <Button
                disabled={getButtonDisabledValue()}
                style={
                  getButtonDisabledValue()
                    ? {
                        width: 154,
                        height: 32,
                        padding: 0,
                      }
                    : {
                        width: 154,
                        height: 32,
                        padding: 0,
                        backgroundColor: "rgba(24, 144, 255, 0.1)",
                        color: "#1890FF",
                        border: "1px solid #1890FF",
                      }
                }
                onClick={handleMoveToTradingStatementPageClick}
              >
                거래명세서 생성
              </Button>
              <Button
                style={{
                  marginLeft: 8,
                  width: 154,
                  height: 32,
                  padding: 0,
                }}
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();

                  downloadBid(quotations.bidId);
                }}
              >
                견적서 다운로드
                <DownloadOutlined />
              </Button>
            </div>
          }
        >
          {quotationsArray.map((v) => {
            return (
              <>
                <Descriptions.Item label="견적 담당자">
                  셀러노트
                </Descriptions.Item>

                <Descriptions.Item label="제출가">{`${
                  bidData.locale === "KR"
                    ? `${numberFormat(v.totalPrice)}원`
                    : `SGD ${numberFormat(v.totalPrice)}`
                }
                  
                `}</Descriptions.Item>

                <Descriptions.Item label="제출일자">
                  {new Date(v.createdAt).toLocaleDateString()}
                </Descriptions.Item>

                <Descriptions.Item label="유효기간">
                  {new Date(v.expiredAt).toLocaleDateString()}
                </Descriptions.Item>

                <Descriptions.Item label="상태">
                  {changeQuotationsStatusToKr(v.status)}
                </Descriptions.Item>

                <Descriptions.Item label="자동견적 여부">
                  {bidData.isFixed ? "O" : "X"}
                </Descriptions.Item>

                {checkIsCouponButton(bidData.projectStatus) && (
                  <Descriptions.Item label="쿠폰 등록">
                    <Button
                      type="primary"
                      disabled={bidData.coupon !== null}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowUserCouponModal(true);
                      }}
                      style={{ width: 42, height: 24, padding: 0 }}
                    >
                      등록
                    </Button>
                  </Descriptions.Item>
                )}

                {checkIsCouponButton(bidData.projectStatus) && (
                  <Descriptions.Item label="쿠폰 삭제">
                    <Button
                      disabled={bidData.coupon === null}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteCoupon();
                      }}
                      danger={true}
                      style={{ width: 42, height: 24, padding: 0 }}
                    >
                      삭제
                    </Button>
                  </Descriptions.Item>
                )}
              </>
            );
          })}
        </Descriptions>
      </Card>

      {showUserCouponModal && (
        <UserCouponModal
          freightType={bidData.freightType}
          setShowUserCouponModal={setShowUserCouponModal}
          showUserCouponModal={showUserCouponModal}
          id={bidData.userId}
          bidId={bidData.id}
        />
      )}
    </div>
  );
};

export default MarginQuotationDesc;
