import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import {
  BidCreateFormData,
  BidCreateStorageData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

export default function useCheckBlankInFormData({
  sessionStorageShipmentCreateInfo,
}: {
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const checkBlankInInvoicePrice = useCallback(
    (formData: BidCreateFormData) => {
      if (!sessionStorageShipmentCreateInfo.isOpenApiAuth) {
        return false;
      }

      if (
        !formData.invoicePrice?.currency ||
        !formData.invoicePrice.foreignPrice
      ) {
        return true;
      }
      return false;
    },
    [sessionStorageShipmentCreateInfo.isOpenApiAuth]
  );

  const checkBlankInEndTypeSection = useCallback(
    (formData: BidCreateFormData) => {
      if (formData.endType === "inland") {
        if (!formData.endAddress || !formData.endViaPort) {
          return true;
        }
        return false;
      }

      if (!formData.endPort) {
        return true;
      }
      return false;
    },
    []
  );

  const checkBlankInStartTypeSection = useCallback(
    (formData: BidCreateFormData) => {
      if (formData.startType === "inland") {
        if (!formData.startAddress || !formData.startViaPort) {
          return true;
        }
        return false;
      }

      if (!formData.startPort) {
        return true;
      }
      return false;
    },
    []
  );

  const checkBlankInTransportSection = useCallback(
    (formData: BidCreateFormData) => {
      if (
        checkBlankInEndTypeSection(formData) ||
        checkBlankInStartTypeSection(formData)
      ) {
        return true;
      }

      return false;
    },
    [checkBlankInEndTypeSection, checkBlankInStartTypeSection]
  );

  const checkBlankInContainersInfo = useCallback(
    (formData: BidCreateFormData) => {
      if (!formData.containersInfo || !formData.containersInfo.length) {
        return true;
      }

      // OPEN API의 경우에는 productName을 보여주기 때문에 name을 빼고 productName을 확인한다.
      if (sessionStorageShipmentCreateInfo.isOpenApiAuth) {
        return formData.containersInfo.some((v) => {
          return (
            v.products?.some((product) => {
              return !product.productName;
            }) ||
            !v.containerList.length ||
            v.containerList.some(
              (container) => !container.containerType || !container.quantity
            )
          );
        });
      }

      return formData.containersInfo.some((v) => {
        return (
          !v.name ||
          !v.containerList.length ||
          v.containerList.some(
            (container) => !container.containerType || !container.quantity
          )
        );
      });
    },
    [sessionStorageShipmentCreateInfo.isOpenApiAuth]
  );

  const checkBlankInProductsInfo = useCallback(
    (formData: BidCreateFormData) => {
      if (!formData.productsInfo) {
        return true;
      }

      if (cargoInfoFormType === "totalVolume") {
        return formData.productsInfo.some((v) => {
          return (
            !v.cbm ||
            !v.name ||
            !v.packingType ||
            !v.quantity ||
            !v.weight ||
            !v.weightUnit
          );
        });
      }

      return formData.productsInfo.some((v) => {
        return (
          !v.height ||
          !v.vertical ||
          !v.horizontal ||
          !v.name ||
          !v.packingType ||
          !v.quantity ||
          !v.weight ||
          !v.weightUnit
        );
      });
    },
    [cargoInfoFormType]
  );

  const checkBlankInCargoInfo = useCallback(
    (formData: BidCreateFormData) => {
      if (formData.freightType === "FCL") {
        if (checkBlankInContainersInfo(formData)) {
          return true;
        }
        return false;
      }

      if (checkBlankInProductsInfo(formData)) {
        return true;
      }
      return false;
    },
    [checkBlankInContainersInfo, checkBlankInProductsInfo]
  );

  const checkBlankInBidCreateForm = useCallback(
    (formData: BidCreateFormData) => {
      if (
        !formData.freightType ||
        checkBlankInTransportSection(formData) ||
        checkBlankInCargoInfo(formData) ||
        checkBlankInInvoicePrice(formData) ||
        !formData.incoterms
      ) {
        return true;
      }
      return false;
    },
    [
      checkBlankInCargoInfo,
      checkBlankInInvoicePrice,
      checkBlankInTransportSection,
    ]
  );

  return { checkBlankInBidCreateForm };
}
