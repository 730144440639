import { useMemo } from "react";
import { UseFormWatch } from "react-hook-form";

import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_CREATE_QUERY";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

export default function useIsOceanTicket({
  portList,
  watch,
}: {
  portList: Port[];
  watch: UseFormWatch<BidCreateFormData>;
}) {
  const endType = watch("endType");

  const startType = watch("startType");

  const startPort = watch("startPort");

  const startViaPort = watch("startViaPort");

  const endViaPort = watch("endViaPort");

  const endPort = watch("endPort");

  const incoterms = watch("incoterms");

  const freightType = watch("freightType");

  const startPortId = useMemo(() => {
    if (startType === "inland") {
      return (
        portList.find((v) => {
          return (
            `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${
              v.code ?? ""
            })` === startViaPort
          );
        })?.id || 0
      );
    }

    return (
      portList.find((v) => {
        return (
          `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
          startPort
        );
      })?.id || 0
    );
  }, [portList, startPort, startType, startViaPort]);

  const endPortId = useMemo(() => {
    if (endType === "inland") {
      return (
        portList.find((v) => {
          return (
            `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${
              v.code ?? ""
            })` === endViaPort
          );
        })?.id || 0
      );
    }

    return (
      portList.find((v) => {
        return (
          `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
          endPort
        );
      })?.id || 0
    );
  }, [endPort, endType, endViaPort, portList]);

  const enabled = useMemo(() => {
    if (startPortId && endPortId && freightType === "LCL") {
      return true;
    }
    return false;
  }, [endPortId, freightType, startPortId]);

  const { data } = ADMIN_BID_CREATE_QUERY.useCheckIsOceanticket({
    startPort: startPortId,
    endPort: endPortId,
    enabled,
  });

  const isOceanticket = useMemo(() => {
    if (
      incoterms !== "CFR" &&
      incoterms !== "CIF" &&
      incoterms !== "CPT" &&
      incoterms !== "CIP" &&
      freightType === "LCL" &&
      data
    ) {
      return true;
    }

    return false;
  }, [data, freightType, incoterms]);

  return { isOceanticket };
}
