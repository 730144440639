import React, { Suspense } from "react";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import {
  useGetAccountList,
  useHandleCreateForm,
  useHandleCreateModal,
  useHandleFileRefList,
  useHandleTeamAndPartnerModal,
} from "./hooks";
import useCreateAccount from "./hooks/useCreateAccount";
import useGetAccountDetail from "./hooks/useGetAccountDetail";
import useUpdateAccountDetail from "./hooks/useUpdateAccountDetail";

import AccountManagementTable from "./AccountManagementTable";
import FilterAndButton from "./FilterAndButton";

const DetailFormModal = React.lazy(() => import("./DetailFormModal"));

export default function Content() {
  const { authority } = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const mappedTeamList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_TEAM_LIST
  );

  const mappedPartnerList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_PARTNER_LIST
  );

  const { fileRefList, setFileRefList } = useHandleFileRefList();

  const {
    opensTeamMappingModal,
    opensPartnerMappingModal,
    handleMappingModalOpen,
    handleMappingModalClose,
  } = useHandleTeamAndPartnerModal();

  const { opensCreateModal, handleCreateModalOpen, handleModalClose } =
    useHandleCreateModal();

  const { data, selectedId } = useGetAccountList();

  const { detailData } = useGetAccountDetail();

  const {
    newAccountForm,
    handleFormUpdate,
    category,
    handleCategoryUpdate,
    resetForm,
  } = useHandleCreateForm({ detailData });

  const { handleAccountCreate } = useCreateAccount({
    newAccountForm,
    category,
    fileRefList,
    onModalClose: handleModalClose,
    resetForm,
  });

  const { handleDetailUpdate } = useUpdateAccountDetail({
    detailData,
    newAccountForm,
    category,
  });

  /** 정보 수정 input 비활성화 로직. true를 리턴하는 경우 disabled */
  const disablesUpdate = (() => {
    /** 정산 담당자는 조건없이 거래처 수정 가능 */
    if (authority === "finance") return false;

    /** 매출처, 매입처가 매핑 돼있는지 */
    const hasMappedTeam = Boolean(mappedTeamList.length);
    const hasMappedPartner = Boolean(mappedPartnerList.length);

    /** 정산 담당자가 아닌 경우, 매출처만 매핑돼있는 경우 수정 가능 */
    if (hasMappedTeam && !hasMappedPartner) return false;

    return true;
  })();

  return (
    <>
      <Box
        bgcolor="white"
        mt={2}
        border={1}
        padding={1}
        borderColor={"#dddddd"}
      >
        <FilterAndButton onCreateModalOpen={handleCreateModalOpen} />
      </Box>

      <AccountManagementTable
        total={data?.total ?? 0}
        accountList={data?.list ?? []}
      />

      <Suspense fallback={<Loading active={true} />}>
        <DetailFormModal
          /** 테이블에서 선택된 열이 있거나, 거래처 등록 버튼이 눌렸을 때 */
          opensModal={Boolean(selectedId) || opensCreateModal}
          onCloseModal={handleModalClose}
          onFormUpdate={handleFormUpdate}
          onCategoryUpdate={handleCategoryUpdate}
          onAccountCreate={handleAccountCreate}
          detailData={detailData}
          onDetailUpdate={handleDetailUpdate}
          newAccountForm={newAccountForm}
          resetForm={resetForm}
          opensTeamMappingModal={opensTeamMappingModal}
          opensPartnerMappingModal={opensPartnerMappingModal}
          onMappingModalOpen={handleMappingModalOpen}
          onMappingModalClose={handleMappingModalClose}
          setFileRefList={setFileRefList}
          disablesUpdate={disablesUpdate}
        />
      </Suspense>
    </>
  );
}
