export const HEAD_CELL_LIST = [
  {
    id: "No.",
    disablePadding: false,
    label: "No.",
    width: 30,
  },
  {
    id: "구분",
    disablePadding: false,
    label: "구분",
    width: 40,
  },
  {
    id: "거래형태",
    disablePadding: false,
    label: "거래형태",
    width: 50,
  },
  {
    id: "거래처명",
    disablePadding: false,
    label: "거래처명",
    width: 100,
  },
  {
    id: "사업자등록번호",
    disablePadding: false,
    label: "사업자등록번호",
    width: 80,
  },
  {
    id: "대표자 성명",
    disablePadding: false,
    label: "대표자 성명",
    width: 70,
  },
  {
    id: "업무 이메일",
    disablePadding: false,
    label: "업무 이메일",
    width: 100,
  },
  {
    id: "세금 이메일",
    disablePadding: false,
    label: "세금 이메일",
    width: 100,
  },
  {
    id: "지급조건",
    disablePadding: false,
    label: "지급조건",
    width: 50,
  },
  {
    id: "팀명",
    disablePadding: false,
    label: "팀명",
    width: 100,
  },
  {
    id: "파트너명",
    disablePadding: false,
    label: "파트너명",
    width: 100,
  },
];
