import { AlertColor } from "@mui/material";

import { CREATE_ACCOUNT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminAccount";
import {
  AccountCategory,
  AccountDetailInfo,
  AccountFileRef,
} from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";

import { AccountCategoryState } from "../types";

const getCategory = (
  category: AccountCategoryState
): {
  category?: AccountCategory;
} => {
  if (category.payable && category.sales) {
    return { category: "all" };
  }

  if (category.sales) {
    return { category: "sales" };
  }

  if (category.payable) {
    return { category: "payable" };
  }

  return {};
};

const validateCreateAccount = ({
  newAccountForm,
  category,
  fileRefList,
  onSnackbarOpen,
}: {
  newAccountForm: CREATE_ACCOUNT_REQ;
  category: AccountCategoryState;
  fileRefList: AccountFileRef[];
  onSnackbarOpen: (message: string, color?: AlertColor) => void;
}) => {
  const selectedCategory = getCategory(category);

  const hasBRN = fileRefList.find(
    (file) => file.domain === "financialAccountBRN"
  );

  const hasBankBookCopy = fileRefList.find(
    (file) => file.domain === "financialAccountBankBookCopy"
  );

  /** 구분(국가) 선택 확인 */
  if (!newAccountForm.businessArea) {
    onSnackbarOpen("구분을 선택해주세요.", "warning");
    return false;
  }

  /** 거래형태 체크 확인 */
  if (isEmptyObjectOrArray(selectedCategory)) {
    onSnackbarOpen("거래 형태를 선택해주세요.", "warning");
    return false;
  }

  /** 거래처명 입력 확인 */
  if (!newAccountForm.name) {
    onSnackbarOpen("거래처명을 입력해주세요.", "warning");
    return false;
  }

  if (!newAccountForm.companyAddress) {
    onSnackbarOpen("사업장주소를 입력해주세요.", "warning");
    return false;
  }

  /** 거래처 구분을 국내로 선택시 추가 Validation 진행 */
  if (newAccountForm.businessArea === "domestic") {
    /** 사업자등록번호 입력 확인 */
    if (!newAccountForm.BRN && !newAccountForm.socialSecurityNumber) {
      onSnackbarOpen(
        "사업자등록번호 혹은 주민등록번호를 입력해주세요.",
        "warning"
      );
      return false;
    }

    /** 대표자 성명 입력 확인 */
    if (!newAccountForm.representativeName) {
      onSnackbarOpen("대표자 성명을 입력해주세요.", "warning");
      return false;
    }

    /** 대표자 성명 입력 확인 */
    if (!newAccountForm.companyAddress) {
      onSnackbarOpen("사업장 주소를 입력해주세요.", "warning");
      return false;
    }

    /** 업태 입력 확인 */
    if (!newAccountForm.sector) {
      onSnackbarOpen("업태를 입력해주세요.", "warning");
      return false;
    }

    /** 종목 입력 확인 */
    if (!newAccountForm.businessCondition) {
      onSnackbarOpen("종목을 입력해주세요.", "warning");
      return false;
    }

    /** 계좌번호 입력 확인 */
    if (!newAccountForm.bankAccount) {
      onSnackbarOpen("계좌번호를 입력해주세요.", "warning");
      return false;
    }

    /** 금융기관 및 예금주 입력 확인 */
    if (!newAccountForm.bankMetadata) {
      onSnackbarOpen("금융기관 및 예금주를 입력해주세요.", "warning");
      return false;
    }

    if (!hasBRN) {
      onSnackbarOpen("사업자등록증을 업로드해주세요.", "warning");
      return false;
    }

    /** 매입의 경우 통장사본이 필수 */
    if (category.payable && !hasBankBookCopy) {
      onSnackbarOpen("통장사본을 업로드해주세요.", "warning");
      return false;
    }
  }

  return true;
};

const lengthWithoutHyphen = (val: string | undefined) => {
  if (!val) return 0;

  return val.replace(/-/g, "").length;
};

const validateUpdateAccount = ({
  detailData,
  newAccountForm,
  category,
  onSnackbarOpen,
}: {
  detailData: AccountDetailInfo | undefined;
  newAccountForm: CREATE_ACCOUNT_REQ;
  category: AccountCategoryState;
  onSnackbarOpen: (message: string, color?: AlertColor) => void;
}) => {
  /** 하이픈(-)을 제외한 length로 검증함 */
  const BRNLength = lengthWithoutHyphen(newAccountForm.BRN);

  const socialSecurityNumberLength = lengthWithoutHyphen(
    newAccountForm.socialSecurityNumber
  );

  const hasBRN = detailData?.files.find(
    (file) => file.domain === "financialAccountBRN"
  );

  const hasBankBookCopy = detailData?.files.find(
    (file) => file.domain === "financialAccountBankBookCopy"
  );

  /** 구분이 국내이거나, 국내를 선택하면 */
  const isDomestic =
    detailData?.businessArea === "domestic" ||
    newAccountForm.businessArea === "domestic";

  /**
   * 거래형태가 매입이거나, 매입을 선택하면
   * detailData.category를 참조하지 않는 이유는, 응답값이 category 상태와 동기화 됨
   */
  const isPayable = category.payable;

  if ("BRN" in newAccountForm && BRNLength !== 10) {
    onSnackbarOpen("사업자등록번호를 확인해주세요.", "warning");
    return false;
  }

  if (
    "socialSecurityNumber" in newAccountForm &&
    socialSecurityNumberLength !== 13
  ) {
    onSnackbarOpen("주민등록번호를 확인해주세요.", "warning");
    return false;
  }

  if (!category.payable && !category.sales) {
    onSnackbarOpen("거래 형태를 선택해주세요.", "warning");
    return false;
  }

  /** 구분이 국내인 경우 첨부파일 유효성 검사 진행 */
  if (isDomestic) {
    if (!hasBRN) {
      onSnackbarOpen("사업자등록증을 업로드해주세요.", "warning");
      return false;
    }

    /** 거래형태가 매입인 경우 통장사본 필수 */
    if (isPayable) {
      if (!hasBankBookCopy) {
        onSnackbarOpen("통장사본을 업로드해주세요.", "warning");
        return false;
      }
    }

    return true;
  }

  return true;
};

export { getCategory, validateCreateAccount, validateUpdateAccount };
