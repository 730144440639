import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import {
  AdminBidDetail,
  BidQuotation,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import { numberFormat } from "services/util";

const BUTTON_STYLE = { color: "#1890FF", p: 0 };

const QuotationDesc = ({
  quotations,
  bidData,
}: {
  quotations: BidQuotation[];
  bidData: AdminBidDetail;
}) => {
  const history = useHistory();

  return (
    <Box pb={2}>
      {quotations.length > 0 && (
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="h6">견적서 초안</Typography>

          <Box display={"flex"} flexDirection={"column"} mt={2} gap={2}>
            {quotations.map((v, index) => {
              return (
                <Box key={index} display="flex" flexDirection={"column"}>
                  <Typography
                    style={{
                      color: BUTTON_STYLE.color,
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    ‣ 초안{index + 1}
                  </Typography>

                  <Box display="flex" alignItems="center" gap={20} mt={2}>
                    <Box>
                      제출가: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {numberFormat(v.totalPrice)}원
                      </Typography>
                    </Box>

                    <Box>
                      제출일자: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {toFormattedDate(v.createdAt, "YYYY-MM-DD")}
                      </Typography>
                    </Box>

                    <Box>
                      유효기간: &nbsp;
                      <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {toFormattedDate(v.expiredAt, "YYYY-MM-DD")}
                      </Typography>
                    </Box>

                    {bidData.quotationsUser ? (
                      <Box>
                        초안{index + 1} 확인: &nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          sx={BUTTON_STYLE}
                          onClick={() => {
                            history.push(
                              `/bid/change-quotation/${bidData.id}/${bidData.freightType}`
                            );
                          }}
                        >
                          확인
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        초안{index + 1} 수정: &nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          sx={BUTTON_STYLE}
                          onClick={() => {
                            history.push(
                              `/bid/change-quotation/${bidData.id}/${bidData.freightType}`
                            );
                          }}
                        >
                          수정
                        </Button>
                      </Box>
                    )}

                    {bidData.quotationsUser.length === 0 && (
                      <Box>
                        초안{index + 1} 선택: &nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          sx={BUTTON_STYLE}
                          onClick={() => {
                            history.push(
                              `/bid/confirm-quotation/${bidData.id}`
                            );
                          }}
                        >
                          선택
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}

      {quotations.length === 0 && bidData.status === "estimating" && (
        <Button
          variant="contained"
          onClick={() => {
            history.push(`/bid/applyBid/${bidData.freightType}/${bidData.id}`);
          }}
        >
          견적 지원
        </Button>
      )}
    </Box>
  );
};

export default QuotationDesc;
