import { ApplyBidFeeData } from "@sellernote/_shared/src/types/forwarding/adminBid";

/**
 * 견적가 비교를 위해 추가한 previousItemPrice를 요청 시 제거해주기 위한 함수
 * @param param0
 * @returns previousItemPrice가 제거된 요청 feeList
 */
function removePreviousItemPrice({ feeList }: { feeList: ApplyBidFeeData[] }) {
  return feeList.map((fee) => {
    const { previousItemPrice, ...newFee } = fee;
    return newFee;
  });
}

export { removePreviousItemPrice };
