/**
 * 로딩 프로그레스 인디게이터
 */
import { Spin } from "antd";

const Loading = () => {
  return (
    <Spin
      size="large"
      style={{
        position: "absolute",
        width: "250px",
        height: "250px",
        left: "50%",
        right: "50%",
        marginTop: "250px",
      }}
    />
  );
};

export default Loading;
