import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";

export default function PartnerSelectConfirmModal({
  onSelectConfirm,
  opensConfirmModal,
  setOpensConfirmModal,
}: {
  onSelectConfirm: () => void;
  opensConfirmModal: boolean;
  setOpensConfirmModal: (val: boolean) => void;
}) {
  return (
    <Modal
      isOpened={opensConfirmModal}
      handleClose={() => setOpensConfirmModal(false)}
      modalBody={
        <>
          <Typography variant="h5">해당 파트너를 선택하시겠습니까?</Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 4,
              marginTop: 4,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onSelectConfirm}
            >
              예
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpensConfirmModal(false)}
            >
              아니오
            </Button>
          </Box>
        </>
      }
    />
  );
}
