import { Divider } from "@mui/material";

import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import Content from "./Content";
import Header from "./Header";

function AccountManagement() {
  return (
    <Layout>
      <Header />

      <Divider sx={{ mt: 1 }} />

      <Content />
    </Layout>
  );
}

export default withRequireAuth(AccountManagement);
