import { useCallback, useState } from "react";

import { AccountParty } from "../types";

export default function useHandleTeamAndPartnerModal() {
  const [mappingModal, setMappingModal] = useState({
    team: false,
    partner: false,
  });

  const handleMappingModalOpen = useCallback(
    (subject: AccountParty) => {
      setMappingModal({
        ...mappingModal,
        [subject]: true,
      });
    },
    [mappingModal]
  );

  const handleMappingModalClose = useCallback(() => {
    setMappingModal({
      team: false,
      partner: false,
    });
  }, []);

  return {
    opensTeamMappingModal: mappingModal.team,
    opensPartnerMappingModal: mappingModal.partner,

    handleMappingModalOpen,
    handleMappingModalClose,
  };
}
