import { Box } from "@mui/material";

import AccountId from "./AccountId";
import Categorization from "./Categorization";
import PaymentCondition from "./PaymentCondition";
import SearchOption from "./SearchOption";
import TradeCategory from "./TradeCategory";

export default function Filter() {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <AccountId />

      <Box borderLeft={"1px solid #dddddd"} />

      <SearchOption />

      <Box borderLeft={"1px solid #dddddd"} />

      <Categorization />

      <TradeCategory />

      <PaymentCondition />
    </Box>
  );
}
