import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";

export default function AIRCheckPoint() {
  const { watch } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containDomesticFee" />
        </>
      )}
    </Grid>
  );
}
