import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";

import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import SwitchWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SwitchWithReactHookForm";

import ContainerList from "./ContainerList";
import ItemName from "./ItemName";

type CellKey = "품명" | "컨테이너" | "위험물" | "삭제";

export default function ContainersInfo({
  productsData,
}: {
  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
}) {
  const { control, watch } = useFormContext<BidCreateFormData>();

  const {
    remove: containerFormRemove,
    append,
    insert,
    fields,
  } = useFieldArray({
    control,
    name: "containersInfo",
  });

  const showsMultiItemName = productsData && productsData.length > 0;

  const headCellList: TableHeadCell<CellKey>[] = [
    {
      cellKey: "품명",
      label: (
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>{showsMultiItemName ? "품명 | 구분자" : "품명"}</Grid>
          <Grid item>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                append({
                  name: "",
                  products: [{ productName: "", separator: undefined }],
                  isDangerous: false,
                  containerList: [
                    {
                      containerType: "20DRY",
                      quantity: 0,
                    },
                  ],
                });
              }}
              size="small"
              endIcon={<AddCircleIcon />}
            >
              품목 추가
            </Button>
          </Grid>
        </Grid>
      ),
      width: 400,
    },
    { cellKey: "컨테이너", label: "컨테이너 X 수량", width: 300 },
    { cellKey: "위험물", label: "위험물", width: 100 },
    { cellKey: "삭제", label: "삭제", align: "center", width: 100 },
  ];

  const bodyRowList: TableBodyRow<CellKey>[] = fields.map((field, index) => {
    return {
      rowKey: field.id,
      품명: {
        value: (
          <ItemName
            control={control}
            formIndex={index}
            productsData={productsData}
            showsMultiItemName={showsMultiItemName}
          />
        ),
      },
      컨테이너: {
        value: <ContainerList formIndex={index} />,
      },
      위험물: {
        value: (
          <SwitchWithReactHookForm
            label=""
            control={control}
            name={`containersInfo.${index}.isDangerous`}
            defaultValue={false}
            value={watch(`containersInfo.${index}.isDangerous`)}
          />
        ),
      },
      삭제: {
        value: (
          <Button
            disabled={fields.length === 1}
            color="warning"
            onClick={() => containerFormRemove(index)}
          >
            <DeleteIcon />
          </Button>
        ),
      },
    };
  });

  return (
    <Grid item width={"1000px"}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </Grid>
  );
}
